<template>
  <section class="custom-background pb-12 overflow-x-hidden">
    <a-spin :spinning="loading">
      <div>
        <!-- ヘッダ直下の画像 ---------+---------+---------+---------+------ -->
        <!-- ＰＣのような幅 640 px 以上の画面で表示 -->
        <div  class="hidden sm:block">
          <div class="mx-auto w-8/12 z-30 relative">
            <!--
            <img
              class="w-full h-96 object-contain cursor-pointer"
              :src="edition.banner_url"
              @click="goToLinkPlace()"
            />
            -->
            <div class="w-full h-full object-scale-down flex items-center justify-center">
              <img
                class="rounded-3xl cursor-pointer"
                :src="edition.banner_url"
                @click="goToLinkPlace()"
              />
            </div>
          </div>
        </div>
        <!-- スマートフォンのような幅 640 px 未満の画面で表示 -->
        <div  class="visible sm:hidden">
          <div class="mx-auto w-full z-30 relative">
            <!--
            <img
              class="w-full h-96 object-scale-down cursor-pointer "
              :src="edition.thumbnail_url"
              @click="goToLinkPlace()"
            />
            -->
            <div class="w-full h-full object-scale-down flex items-center justify-center">
              <img
                class="cursor-pointer"
                :src="edition.banner_url"
                @click="goToLinkPlace()"
              />
            </div>
          </div>
        </div>
        <!-- プロジェクトシリーズエディション紹介テキスト -+---------+------ -->
        <div
              v-if="localizedDescription"
              v-html="$sanitize(localizedDescription)"
              class="width-1140-no-padding py-2 px-4 mx-auto mt-8 mb-4 text-lg text-black description-CSS"
        ></div>
        <!-- プロジェクトシリーズエディションアイテム 12枚のカードリスト --- -->
        <div style="width: 1140px" class="mx-auto w-full z-30 relative mt-10">
          <div class="title custom-title">{{ $t('PROJECT_NFT_LIST_SECTION_TITLE') }}</div> <!-- ＮＦＴ一覧 -->
        </div>
        <div
          class="mx-auto width-1140"
        >
          <CardList
            @onPageChange="onPageChange"
            :data="list"
          ></CardList>
        </div>
        <!-- プロジェクトシリーズエディションアイテム が無い場合ト --+------ -->
        <!--
        <a-empty
          class="mt-32"
          v-else
        ></a-empty>
        -->
      </div>
    </a-spin>
  </section>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import { PICTURE_PER_PAGE } from '@/constants/common.constant';
import CardList from '@/components/nft/CardList/index.vue';
import { mapActions, mapState } from 'vuex';
import { getCategories } from '@/services/categories';
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'page-home',
  // ----+---------+---------+---------+---------+---------+---------+---------E
  components: { CardList },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      edition: '',
      creators: [],
    }; // End of return {...}
  }, // End of data() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  metaInfo: {
    title: 'BCG・GameFi 専門のNFT1次販売ローンチパッド - Zaif INO',
    // meta: [
    //     {
    //         vmid: "description",
    //         property: "description",
    //         content:
    //             "ブロックチェーンゲーム・GameFi専門のNFT1次販売ローンチパッドのZaif INO。事前審査制導入で取り扱いNFTのクオリティを担保し、パブリックチェーンで海外プロジェクトの取り扱いも行います。",
    //     },
    //     {
    //         property: "og:title",
    //         content: "BCG・GameFi 専門のNFT1次販売ローンチパッド - Zaif INO",
    //         vmid: "og:title",
    //     },
    // ],
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapState({
      list: (state) => state.marketplace.marketplaceList,
      total: (state) => state.marketplace.totalItems || 1,
      locale: (state) => state?.setting.locale,
      // パンくずリストをヘッダに書くためのコード。現在未使用。
      hierarchy: (state) => state.hierarchy.hierarchy,
      name_en  : (state) => state.hierarchy.name_en,
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    localizedDescription() {
      const result = (this.locale === 'en'  ? this.edition.description_en
                                            : this.edition.description_ja
      );
      return result;
    },
  }, // End of computed: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  async mounted() {
    await this.getEdition(this.id);
    await this.fetchNewPage(1);
    // パンくずリストをヘッダに書くためのコード。現在未使用。
    this.setHierarchy({hierarchy: this.edition.hierarchy,
                         name_en: this.edition.name_en,
                         name_ja: this.edition.name_ja,
    });
  }, // End of  async mounted() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    ...mapActions({
      fetchMarketplace: 'marketplace/fetchMarketplaceList',
      // パンくずリストをヘッダに書くためのコード。現在未使用。
      setHierarchy: 'hierarchy/setHierarchy',
    }),
    // ページが切り替わったら実行。
    async onPageChange(page) {
      await this.fetchNewPage(page);
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル categories から
    // エディション情報（オブジェクト）を一つ取得し this.edition に取り込む。
    async getEdition(id) {
      const {total: total, items: items} = await getCategories({
        type:  3,
        id  : id,
      });
      if (total==0) {
        // データが取得できなかったらエラーページに遷移。
        await this.$router.push(`/ERROR_PAGE`)
      }
      this.edition = items[0];
    }, // End of async getEdition(id) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル projects から
    // レコード情報（オブジェクト）を一ページ分 12 個取得。store に反映。
    // 「階層」hierarchy がこの画面のシリーズの画像情報のみ取得。
    async fetchNewPage(page) {
      // alert(`*** START: fetchNewPage: edition.page.vue ***`);
      this.loading = true;
      const offset = PICTURE_PER_PAGE * (page - 1);
      await this.fetchMarketplace({
        limit: PICTURE_PER_PAGE,
        offset,
        hierarchy: JSON.stringify([
          this.edition.hierarchy + '\\' + this.edition.name_en
        ]),
      });
      this.loading = false;
      // alert(`*** END  : fetchNewPage: edition.page.vue ***`);
    }, // End of async fetchNewPage(page) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // this.edition.link の URL を開く。一番上の画面をクリックしたときの処理。
    goToLinkPlace() {
      // window.open(this.edition.link, '_blank');
    },
  }, // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  scoped
  lang="scss"
>
.custom-background {
    background-color: white; /* ここで好きな色を指定 */
} 

::v-deep .category-detail-page {
  @apply overflow-hidden;
  @apply px-4;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
  .description {
    a {
      color: #40a9ff;
    }
  }
  &-breadcrumb {
    .ant-breadcrumb {
      span {
        .ant-breadcrumb-link {
          @apply text-white;
          @apply font-medium;
        }
        .ant-breadcrumb-separator {
          color: #fff;
        }
      }
    }
  }
  .combined-target-bar {
    @screen xxs {
      padding-left: 16px;
      padding-right: 16px;
    }
    @screen md {
      padding-left: 32px;
      padding-right: 32px;
    }
    @apply px-1;
    @apply mb-4;
  }
}


.width-1140 {
  @apply px-2;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
}
.width-1140-no-padding {
  @screen xl {
    width: 1140px;
  }
}

.text-top {
  @apply text-2xl;
  @screen xxs {
    @apply text-3xl;
  }
  @screen md {
    @apply text-base;
  }
}
  /* タイトル用の基本スタイル */
  .title {
  display: block;
  font-size: 24px; /* フォントサイズを大きく */
  font-weight: bold; /* フォントを太字に */
  /* color: #FFFFFF; テキストカラー 白 */
  color: #000000; /* テキストカラー 黒 */
  margin-bottom: 20px; /* 下余白 */
  padding: 10px 10px; /* 上下パディング */
}

/* 特定のセクション用のカスタムスタイル */
.custom-title {
  border-radius: 8px; /* 角の丸み */
  text-align: left; /* テキストを中央揃えに */
}
::v-deep .ant-breadcrumb {
  span {
    .ant-breadcrumb-link {
      @apply text-white;
      @apply font-medium;
    }
  }
}
::v-deep .ant-select {
  color: #fff;
  @apply font-semibold;
  &-arrow {
    color: white;
  }
  &-selection {
    background-color: transparent;
    border: 1px solid white;
    @apply rounded-xl;
    @apply w-32 h-6;
    @apply text-xs;
    &__rendered {
      @apply mx-0;
      @apply w-full h-full;
      .ant-select-selection-selected-value {
        @apply w-full h-full text-center relative -top-1;
      }
    }
  }
}
/*以下カテゴリ紹介文内のCSSに適用 */
::v-deep .description-CSS p {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}

::v-deep .description-CSS img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}

::v-deep .description-CSS a > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}

/*以下カテゴリ紹介文内のCSSに適用 */
::v-deep .description-CSS p {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
}
</style>
